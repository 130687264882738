import React, {useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Home from './home';
import "../css/index.css";
import Modules from "./modules";
import Contact from "./contact";
import Menu from "./menu";
import BGLogo from "../images/BGLogo.png";
import smoothscroll from 'smoothscroll-polyfill';
import ImageSlider from './imageSlider';

// styles


const business = {
  name: "BUSINESS",
  tag: "business",
  description: "Sunt et adipisicing veniam consectetur velit laboris ut officia in laboris. Cillum qui sunt labore eiusmod culpa ullamco culpa incididunt aliquip ex. Do est aliquip dolor adipisicing pariatur dolor sint esse. Magna proident proident cillum est quis cupidatat nostrud aute mollit est adipisicing. Consequat amet sit culpa elit eiusmod aliquip ipsum deserunt sint. Magna ex et non eu non incididunt. Deserunt quis ut proident anim ea irure minim ut qui eu incididunt.",
}

const company ={
  name: "COMPANY",
  tag: "company",
  description: "Sunt et adipisicing veniam consectetur velit laboris ut officia in laboris. Cillum qui sunt labore eiusmod culpa ullamco culpa incididunt aliquip ex. Do est aliquip dolor adipisicing pariatur dolor sint esse. Magna proident proident cillum est quis cupidatat nostrud aute mollit est adipisicing. Consequat amet sit culpa elit eiusmod aliquip ipsum deserunt sint. Magna ex et non eu non incididunt. Deserunt quis ut proident anim ea irure minim ut qui eu incididunt.",
}

const businessBody = (
  <div id="businessContent">
    <h3>”COMMITMENT TO QUALITY”</h3>
    <p>国内生産と海外生産で、短サイクル・小ロットで価値に見合う商品をプロデュース</p>
    <p>海外輸出、三国間貿易、DropShip対応</p>
    <h4>取扱商品</h4>
    <p>メンズ・レディース・キッズ衣料品</p>
    <p>布帛、カットソー、ニット異素材コンビ、レザー、毛皮製品</p>
    {<ImageSlider />}
    <h4>工場拠点</h4>
    <p>国内：秋田・山梨・岐阜・鳥取・高知・宮崎・鹿児島</p>
    <p>中国：大連・蘇州・東莞・中山</p>
    <hr></hr>
    <h3>NEW PROJECT COMING SOON...</h3>
    <p>世界へ羽ばたく - 新プロジェクト進行中</p>
    <p>RISEUPは変化を恐れず、新しい世界にチャレンジしていきます</p>
  </div>
);

const companyBody = (
  <div　id="companyContent">
    <h4>会社概要</h4>
    <table id="companyTable">
      <tbody>
        <tr>
          <th>会社名</th>
          <td>株式会社ライズアップ　(RISEUP INC.)</td>
        </tr>
        <tr>
          <th>設立</th>
          <td>2007年10月1日</td>
        </tr>
        <tr>
          <th>本社所在地</th>
          <td>
            <p>〒604-8812</p>
            <p>京都府京都市中京区壬生相合町67-1</p>
          </td>
        </tr>
        <tr>
          <th>東京営業所</th>
          <td>
            <p>〒151-0071</p>
            <p>東京都渋谷区本町4-23-0 サンハイムアオキ103</p>
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>info@riseup-inc.com</td>
        </tr>
        <tr>
          <th>ホームページURL</th>
          <td>https://www.riseup-inc.com</td>
        </tr>
        <tr>
          <th>代表者</th>
          <td>代表取締役　中西隆善</td>
        </tr>
        <tr>
          <th>資本金</th>
          <td>JP¥5,000,000</td>
        </tr>
        <tr>
          <th>年商</th>
          <td>JP¥100,000,000</td>
        </tr>
        <tr>
          <th>事業内容</th>
          <td>
            <p>繊維製品・雑貨の企画、製造、販売、輸出入</p>
            <p>小売業</p>
            <p>マネジメント事業</p>
          </td>
        </tr>
      </tbody>
      
    </table>
  </div>
);

const contact ={
  name: "CONTACT",
  tag: "contact",
}

const logo = {
  name: "TOP",
  tag: "mainFrame",
}


// markup
const IndexPage = () => {
  const [isLoading, setLoad] = useState('loading');
  const [offsetY, setOffsetY] = useState(0);
  const [backgroundRatio, setBackgroundR] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  const handleResize = () => {
    let bgHeight = document.getElementById("backgroundLogo").height;
    let docHeight = document.getElementById("mainFrame-module").clientHeight;
    let scrollHeight = docHeight - window.innerHeight;
    let ratio = (docHeight - bgHeight)/scrollHeight;
    setBackgroundR(ratio);
  };

  const mainFrameStyle = {
    color:"22222",
    padding: "0",
    height: "300%",
    position: "relative",
  }

  useEffect(() => {
    document.title="RISEUP INC.";
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    setLoad('');
    smoothscroll.polyfill();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      handleResize();
    }, 300);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // below is HTML-----------------------------------------------------
  return (
    <div id="background">
      <img id="backgroundLogo" src={BGLogo} alt='' className={isLoading} style={{
        transform: `translateY(${offsetY * backgroundRatio}px)`
        }}></img>
      <Grid container style={mainFrameStyle} id="mainFrame-module" className={isLoading}>

        <Home></Home> 
        <div className="main-flex-container">
          <div className="module-container">
            <Modules name={business.name} description={business.description} tag={business.tag} body={businessBody}></Modules>
            <Modules name={company.name} description={company.description} tag={company.tag} body={companyBody}></Modules>
            <Modules name={contact.name} body={<Contact />} tag={contact.tag}></Modules>
          </div>

          <Menu logo={logo} business={business} company={company} contact={contact}/>
       </div>
       <div id="footer"><p>ⒸRISEUP INC. all rigths reserved</p></div>
      </Grid>
    </div>
  )
}

export default IndexPage
