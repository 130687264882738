import React, {useEffect } from 'react';
import "../css/menuItem.css";


const MenuItem = (props) => {

   const scroll = (e) => {
      var rect = document.getElementById(e.target.id+"-module").getBoundingClientRect();
      var position = rect.top + window.pageYOffset;;
      window.scroll({
        top: position,
        behavior: "smooth",
      });
   };

    
   useEffect(() => { 
   }, []);


   return (
      <div id={props.tag} className="menu-item" onClick={scroll} >
           <hr className="menu-line"></hr>
           <a className="menu-name" >{props.name}</a>
      </div>
   )
}


export default MenuItem