import React, {useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import "../css/modules.css";
import { gsap } from 'gsap/all';
import { ScrollTrigger } from "gsap/ScrollTrigger";


const backgroundStyle = {
    width: '100%',
    height: 'auto',
    marginBottom: 'calc(50px + 5vh + 5vw)',
    backgroundColor: '#fffff',
    // backgroundColor:'grey',
    position: "relative",
}

const Modules = (props) => {
    const revealAnimation = (elem, direction) => {
        direction = direction || 1;
        let x = 0, y = direction * 100;
        if(elem.classList.contains("LReveal")) {
            x = -100;
            y = 0;
        }
        elem.style.transform = "translate(" + x + "px, " + y + "px)";
        elem.style.opacity = "0";
        gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
            duration: 1.25, 
            x: 0,
            y: 0, 
            autoAlpha: 1, 
            ease: "expo", 
            overwrite: "auto"
        });
    }
    const hideAnimation = (elem, direction) => {
        direction = direction || 1;
        let x = 0, y = -direction * 100;
        if(elem.classList.contains("LReveal")) {
            x = -100;
            y = 0;
        }
        elem.style.transform = "translate(" - x + "px, " + y + "px)";
        elem.style.opacity = "0";
        gsap.fromTo(elem, {x: 0, y: 0, autoAlpha: 1}, {
            duration: 1.25, 
            x: x,
            y: y, 
            autoAlpha: 0, 
            ease: "expo", 
            overwrite: "auto"
        });
    }
      
    const hide = (elem) => {
        gsap.set(elem, {autoAlpha: 0});
    }

    
    useEffect(() => {
        const mountAnimation = () => {
            gsap.registerPlugin(ScrollTrigger);
            gsap.utils.toArray(".reveal").forEach(function(elem) {
                hide(elem);

                ScrollTrigger.create({
                trigger: elem,
                start:"top 60%",
                end:"center top",
                onEnter: function() { revealAnimation(elem) }, 
                onEnterBack: function() { revealAnimation(elem, -1) },
                onLeave: function() { hideAnimation(elem) },
                onLeaveBack: function() {hideAnimation(elem, -1)}
                });
            });
        }
        mountAnimation();
    }, []);

    
    
    return (
        <Grid item xs={12} style={backgroundStyle} id={props.tag + "-module"}>
            <div className="reveal LReveal">
                <h1 className="name">{props.name}</h1>
                <div className="nameBase"></div>
            </div>
            <div className="description reveal">{props.body}</div>
            
        </Grid>
    )
}

export default Modules