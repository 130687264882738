import React, {useEffect } from 'react';
import "../css/menu.css";
import { gsap } from 'gsap/all';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MenuItem from "./menuItem";

const Menu = (props) => {
    const contact ={
        name: "CONTACT",
        tag: "contact",
    }
    
    const logo = {
        name: "TOP",
        tag: "mainFrame",
    }
    const business = {
        name: "BUSINESS",
        tag: "business",
    }
    
    const company ={
        name: "COMPANY",
        tag: "company",
    }
      
      
    const revealAnimation = (elem, direction) => {
        let x = 100;
        let y = 0;
        elem.style.transform = "translate(" + x + "px, " + y + "px)";
        elem.style.opacity = "0";
        gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
            duration: 1.25, 
            x: 0,
            y: 0, 
            autoAlpha: 1, 
            ease: "expo", 
            overwrite: "auto"
        });
    }
    const hideAnimation = (elem, direction) => {
        let x = 100;
        let y = 0;
        elem.style.transform = "translate(" - x + "px, " + y + "px)";
        elem.style.opacity = "0";
        gsap.fromTo(elem, {x: 0, y: 0, autoAlpha: 1}, {
            duration: 1.25, 
            x: x,
            y: y, 
            autoAlpha: 0, 
            ease: "expo", 
            overwrite: "auto"
        });
    }
      
    const hide = (elem) => {
        gsap.set(elem, {autoAlpha: 0});
    }

    
    useEffect(() => {
        const mountAnimation = () => {
            gsap.registerPlugin(ScrollTrigger);
            gsap.utils.toArray(".menuReveal").forEach(function(elem) {
                hide(elem);
                
                ScrollTrigger.create({
                    trigger: elem,
                    start: "top 60%",
                    end:"top 60%",
                    onEnter: function() { revealAnimation(elem) },
                    onLeaveBack: function() {hideAnimation(elem, -1)}
                });
            });
        }
        mountAnimation();
    }, []);

    
    
    return (
        <div className="menu-box menuReveal">
            <MenuItem name={logo.name} tag={logo.tag} />
            <MenuItem name={business.name} tag={business.tag} />
            <MenuItem name={company.name} tag={company.tag} />
            <MenuItem name={contact.name} tag={contact.tag} />
        </div>
    )
}

export default Menu