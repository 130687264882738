import React from 'react';
import "../css/contact.css";
import emailjs from '@emailjs/browser';
import emailkeys from '../emailkeys';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            name: '',
            mail:'',
            phone:'',
            msg:''  
        };
        this.setName = this.setName.bind(this);
        this.setMail = this.setMail.bind(this);
        this.setPhone = this.setPhone.bind(this);
        this.setMsg = this.setMsg.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    setName(event) { this.setState({name: event.target.value}); }
    setMail(event) { this.setState({mail: event.target.value}); }
    setPhone(event) {
        const re = /^[0-9- +\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({phone: event.target.value})
        }
    }
    setMsg(event) { this.setState({msg: event.target.value}); }
    handleMissingField(field, id){
        if(field === ''){
            alert('Please fill in your ' + id);
            return true;
        }
        return false;
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.handleMissingField(this.state.name, "name")){return};
        if(this.handleMissingField(this.state.mail, "email")){return};
        if(this.handleMissingField(this.state.phone, "phone number")){return};
        if(this.handleMissingField(this.state.msg, "message")){return};
        
        emailjs.sendForm(emailkeys.SERVICE_ID, emailkeys.TEMPLATE_ID, event.target, emailkeys.USER_ID)
        .then((result) => {
            alert('この度はお問い合わせありがとうございます。メッセージを受け取りました。ご連絡ありがとうございます。');
            this.setState({
                name: '',
                mail:'',
                phone:'',
                msg:''
            });
        }, (error) => {
            console.log(error.text);
        });
    }

    render() {
      return (
        <div id="contactFormBG">
            <form onSubmit={this.handleSubmit}>
                <label id="nameField"  htmlFor="name" className="formElements">氏名</label>
                <input id="name" name="name" className="formElements onelineInput" type="text" placeholder="お名前" value={this.state.name} onChange={this.setName}></input>
                
                <label id="mailField" htmlFor="email" className="formElements">メールアドレス</label>
                <input id="email"name="email" className="formElements onelineInput" type="text" placeholder="smaple@xxx.com" value={this.state.mail} onChange={this.setMail}></input>
                
                <label id="phoneField" htmlFor="phone" className="formElements">電話番号</label>
                <input id="phone" name="phone" className="formElements onelineInput" type="text" placeholder="000-0000-0000" value={this.state.phone} onChange={this.setPhone}></input>
                
                <label id="msgField" htmlFor="message" className="formElements">お問い合わせ内容</label>
                <textarea id="message" name="message" className="formElements onelineInput" rows="10" placeholder="ご入力ください" value={this.state.msg} onChange={this.setMsg}></textarea>
                
                <div id="buttonRow" className="formElements" >
                    <button id="submitButton" type="submit" value="Submit">
                        <span>送信する</span>
                    </button>
                </div>
            </form>
        </div>
      );
    }
}

export default Contact